import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import capitalize from 'capitalize';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTE } from '../../routes';

const UserResultTable = (props) => {
  const { t, users } = props;
  return (
    <table className="table striped text--benton-book" style={{ cellspacing: '0' }}>
      <thead>
        <tr className="text--label">
          <th>{t('Last_Name')}</th>
          <th>{t('First_Name')}</th>
          <th>{t('Email')}</th>
          <th>{t('Phone')}</th>
          <th>{t('Company')}</th>
          <th>{t('Status')}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={user.userId}>
            <td id={`lastName.${index}`}>{user.lastName}</td>
            <td id={`firstName.${index}`}>{user.firstName}</td>
            <td id={`email.${index}`}>
              <Link
                to={{
                  pathname: ADMIN_ROUTE,
                  search: `?email=${encodeURIComponent(user.email)}`,
                }}
                id={user.userId}
              >
                {user.email}
              </Link>
            </td>
            <td id={`phone.${index}`}>{user.phone}</td>
            <td id={`company.${index}`}>{user.company}</td>
            <td id={`status.${index}`}>{t(capitalize.words(user.status))}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

UserResultTable.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default translate()(UserResultTable);
