import { translate } from "react-i18next";
import Formsy from "formsy-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import dompurify from "dompurify";

import { ErrorMessages, TokenTypes } from "../../common/Constants";
import {
  ActivationTitle,
  ActivationDetails
} from "../../common/ActivationPageMessages";
import ValidationInput from "./elements/ValidationInput";

export class TokenForm extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDisabled: false };

    if (props.tokenType === TokenTypes.RESET_PASSWORD) {
      this.buttonText = "Set_New_Password";
      this.title = "New_Password";
      this.passwordTitle = "New_Password";
      this.confirmTitle = "Confirm_New_Password";
      this.buttonId = "resetPasswordButton";
    } else {
      this.buttonText = "Activate";
      this.title = this.getActivationTitle();
      this.details = this.getActivationDetails();
      this.passwordTitle = "Password";
      this.confirmTitle = "Confirm_Password";
      this.buttonId = "activateButton";
    }
    if (!props.hasName && !props.tokenType.includes("Activation")) {
      this.title = "New_Password";
      this.buttonText = "Set_New_Password";
      this.showName = true;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = formData => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => ({
    verified: true,
    email: inputs.email,
    password: inputs.password,
    firstName: inputs.firstName,
    lastName: inputs.lastName
  });

  getActivationTitle = () => this.props.activationTitle && ActivationTitle[this.props.activationTitle]
      ? ActivationTitle[this.props.activationTitle]
      : 'Activate';

  getActivationDetails = () =>
    this.props.activationDetails &&
    ActivationDetails[this.props.activationDetails]
      ? ActivationDetails[this.props.activationDetails]
      : '';

  render() {
    const { tokenType, t } = this.props;

    return (
      <div className="grid--5 form--full-width">
        <h1 className="title">{t(this.title)}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(t(this.details))
          }}
        />
        <Formsy
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <ValidationInput
            autoFocus
            id="password"
            maxLength="100"
            name="password"
            placeholder={t(this.passwordTitle)}
            type="password"
            required
            value=""
            validations="validPassword"
            validationErrors={{
              isDefaultRequiredValue: t("This_field_is_required.")
            }}
            validationError={t(
              "Passwords_must_contain_at_least_one_number_one_uppercase_letter_one_lowercase_letter_one_special_character_and_be_at_least_8_characters_long."
            )}
            title={t(this.passwordTitle)}
          />
          <ValidationInput
            id="confirmPassword"
            maxLength="100"
            name="confirmPassword"
            placeholder={t(this.confirmTitle)}
            type="password"
            required
            value=""
            validations="equalsField:password"
            validationErrors={{
              isDefaultRequiredValue: t("This_field_is_required.")
            }}
            validationError={t("This_must_match_the_Password_field_above.")}
            title={t(this.confirmTitle)}
          />
          {(tokenType === TokenTypes.EMAIL_ONLY_ACTIVATION ||
            this.showName) && (
            <div>
              <ValidationInput
                id="firstName"
                maxLength="40"
                name="firstName"
                placeholder={t("First_Name")}
                type="text"
                required
                value=""
                validationErrors={{
                  isDefaultRequiredValue: t("This_field_is_required.")
                }}
                validations="validName"
                validationError={t(ErrorMessages.firstName)}
                title={t("First_Name")}
              />
              <ValidationInput
                id="lastName"
                maxLength="40"
                name="lastName"
                placeholder={t("Last_Name")}
                type="text"
                required
                value=""
                validationErrors={{
                  isDefaultRequiredValue: t("This_field_is_required.")
                }}
                validations="validName"
                validationError={t(ErrorMessages.lastName)}
                title={t("Last_Name")}
              />
            </div>
          )}
          <p>
            {t(
              "Passwords_are_case_sensitive_8-100_characters_long._Requires_at_least_one_number_0-9_one_uppercase_letter_one_lowercase_letter_and_one_of_the_following_characters:_$_&_-_._:___."
            )}
          </p>
          <div className="form-buttons">
            <button
              type="submit"
              className="cta cta--secondary"
              id={this.buttonId}
              disabled={this.state.submitDisabled}
            >
              {t(this.buttonText)}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

TokenForm.propTypes = {
  t: PropTypes.func.isRequired,
  tokenType: PropTypes.string.isRequired,
  activationTitle: PropTypes.string.isRequired,
  activationDetails: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasName: PropTypes.bool.isRequired
};
export default translate()(TokenForm);
