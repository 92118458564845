import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import History from '../common/History';
import { SEARCH_ROUTE } from '../routes';
import { getProfile } from '../common/SessionManager';
import { MessageType, UserGroups } from '../common/Constants';
import * as actions from '../redux/actions/userActions';
import { translateArray } from '../common/Util';
import UserEditForm from '../components/forms/UserEditForm';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import UserDetailView from '../components/forms/UserDetailView';

class AccountAdmin extends Component {
  componentDidMount() {
    const { email } = this.props;
    if (email) {
      this.props.userActions.searchUsers({ email }, false, false, '');
    } else {
      History.push(SEARCH_ROUTE);
    }
  }

  componentDidUpdate() {
    // Scroll to the top of the page after updating
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    const {
      t, userModel, isFetching, userActions, updateSuccess, updateUserError, userUpdateRequestId,
    } = this.props;
    const profile = getProfile();
    const showGroups = userModel
      && (profile.profileAdminWithGrant
        || (profile.profileAdmin
          && userModel.group !== UserGroups.profileReaders
          && userModel.group !== UserGroups.profileAdmins));

    document.title = t('Tableau_-_Account_Management');
    const updateUser = (userInfo) => {
      userActions.updateUser(userInfo.uid, userInfo);
    };
    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay />}
          {updateUserError && (
            <Message id="errorMessage" text={translateArray(updateUserError)} messageType={MessageType.error} />
          )}
          {updateSuccess && (
            <Message
              id="successMessage"
              text={t('Profile_was_successfully_updated.')}
              messageType={MessageType.notice}
            />
          )}
          {userModel && profile && profile.profileAdmin && (
            <UserEditForm
              userModel={userModel}
              onSubmit={updateUser}
              showGroups={showGroups}
              showAdminGroups={profile.profileAdminWithGrant}
              isFetching={isFetching}
            />
          )}
          {userModel && profile && (profile.profileAdmin || profile.profileReader) && (
            <UserDetailView userModel={userModel} />
          )}
        </div>
        <input type="hidden" id="updateRequestId" value={userUpdateRequestId} />
      </section>
    );
  }
}
AccountAdmin.propTypes = {
  isFetching: PropTypes.bool,
  t: PropTypes.func.isRequired,
  userModel: PropTypes.object,
  userActions: PropTypes.object.isRequired,
  updateSuccess: PropTypes.bool,
  updateUserError: PropTypes.array,
  email: PropTypes.string,
  userUpdateRequestId: PropTypes.string,
};

AccountAdmin.defaultProps = {
  userModel: null,
  isFetching: false,
  updateSuccess: null,
  updateUserError: null,
  email: null,
  userUpdateRequestId: '',
};

const mapStateToProps = state => ({
  updateUserError: state.userUpdateReducer.errorMessage,
  updateSuccess: state.userUpdateReducer.success,
  isFetching: state.userSearchReducer.isFetching || state.userUpdateReducer.isFetching,
  userModel: state.userUpdateReducer.userModel || state.userSearchReducer.userModel,
  email: state.locationReducer.email,
  userUpdateRequestId: state.userUpdateReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(actions, dispatch),
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(AccountAdmin));
