import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { HELP_ROUTE } from '../routes';
import { logError } from '../common/Logger';
import Console from '../common/Console';
import Message from '../components/Message';
import { MessageType } from '../common/Constants';

const ErrorPage = (props) => {
  const {
    t, code, error, errorStack, clientId, queryString,
  } = props;
  const errorDescription = props.errorDescription || '';
  function isEmpty(string) {
    if (string === null || string.trim() === '' || string.trim().length === 0) {
      return true;
    }
    return false;
  }
  let message = `${t('Were_sorry_An_unknown_error_has_occurred.')}
${t('If_the_problem_persists_please_let_us_know.', {
    link: HELP_ROUTE,
  })}`;
  const salesforceLoginRejectMessages = ['blocked by admin', 'ip restricted'];
  const salesforceOauthError = ['access_denied', 'invalid_request'];
  const isSalesforceLoginError = errorDescription
    && salesforceLoginRejectMessages.some(err => errorDescription.includes(err))
    && salesforceOauthError.some(err => error.includes(err));
  const isBookmarkError = errorDescription && errorDescription.includes('bookmark');
  if (isBookmarkError) {
    message = t(
      'It appears you tried to login from a bookmark. Please navigate to your intended destination such as <a href="https://www.tableau.com">www.tableau.com</a> and login from there.',
    );
  }
  if (isSalesforceLoginError) {
    message = '';
    const loginRejectMessage = [
      'Your_Salesforce_Org_is_set_up_to_block_this_authentication_due_to_:',
      ` ${errorDescription.toUpperCase()}`,
      '<br/>',
      'Your_Salesforce_Admin_can_update_it_to_allow_authentication_to_Tableau._Until_then_you_can_login_with_a_different_Salesforce_org_or_create_a_Tableau_Account_to_login.',
    ];
    loginRejectMessage.forEach((val) => {
      message += `${t(val)} `;
    });
  }
  if (code === '404') {
    message = `${t('Were_sorry._The_page_or_URL_you_requested_could_not_be_found.')}
     ${t('If_the_problem_persists_please_let_us_know.', {
    link: HELP_ROUTE,
  })}`;
  }
  let description = errorDescription;
  if (isEmpty(error) && isEmpty(errorStack) && isEmpty(errorDescription) && isEmpty(clientId) && isEmpty(queryString)) {
    description = 'Unspecified error';
  }
  if (!isBookmarkError && !isSalesforceLoginError) {
    try {
      logError({
        code,
        error,
        stack: errorStack,
        description,
        clientId,
        queryString,
      });
    } catch (e) {
      Console.log(error, errorStack, errorDescription);
    }
  }

  const salesforceLoginError = (
    <div className="content-container" id="errorMessage">
      <Message id="salesforce_login_rejected" text={message} messageType={MessageType.error} />
    </div>
  );
  const otherError = (
    <div className="content-container" id="main">
      <div id="form" className="clearfix">
        <h1 className="title">{t('Error')}</h1>
        <p>
          {!isBookmarkError && (
          <strong>
            [
            {code}
            ]
          </strong>
          )}
        </p>
        <p id="errorPage" dangerouslySetInnerHTML={{ __html: message }} />
        {!isBookmarkError && <p>{error}</p>}
      </div>
      <div className="clear" />
    </div>
  );
  return <section className="section">{isSalesforceLoginError ? salesforceLoginError : otherError}</section>;
};

ErrorPage.propTypes = {
  t: PropTypes.func.isRequired,
  code: PropTypes.string,
  error: PropTypes.string,
  errorDescription: PropTypes.string,
  errorStack: PropTypes.string,
  clientId: PropTypes.string,
  queryString: PropTypes.string,
};

ErrorPage.defaultProps = {
  code: '500',
  error: null,
  errorDescription: null,
  errorStack: null,
  clientId: null,
  queryString: null,
};

export default translate()(ErrorPage);
