/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-08 19:22:08
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-29 09:32:38
 */
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import capitalize from 'capitalize';
import { mapFields, getCountryConfig } from '../../common/AddressTool';
import * as Data from '../../common/Data';
import getAddressConfig from './Data/AddressConfig';

const UserDetailView = (props) => {
  const { t, userModel } = props;
  const config = getAddressConfig(props.i18n.language);
  const country = getCountryConfig(config, userModel.country);
  const fields = country && mapFields(country.fields);
  const addressLabels = [];

  const language = () => {
    for (let i = 0; i < Data.LanguageData.length; i += 1) {
      if (Data.LanguageData[i].value === userModel.language) {
        return Data.LanguageData[i].label;
      }
    }
    return null;
  };

  const setLabelsAndAdministrativeArea = () => {
    let options = null;
    let administrativeArea = null;
    for (let i = 0; i < fields.length; i += 1) {
      addressLabels[fields[i].type] = fields[i].label;
      if (fields[i].type === 'administrativearea' && fields[i].options) {
        options = fields[i].options;
      }
    }
    if (options) {
      for (let i = 0; i < options.length; i += 1) {
        if (options[i].value === userModel.administrativearea) {
          administrativeArea = options[i].label;
          break;
        }
      }
    }
    return administrativeArea;
  };
  let administrativearea = fields && setLabelsAndAdministrativeArea();
  if (!administrativearea) {
    administrativearea = userModel.administrativearea;
  }
  const EmailChangeHistoryTable = () => (
    <table className="table-list margin-top-one">
      <thead>
        <tr>
          <th className="heading-highlight-gray" style={{ textAlign: 'center' }}>
            Date
          </th>
          <th className="heading-highlight-gray" style={{ textAlign: 'center' }}>
            Email Changed From
          </th>
          <th className="heading-highlight-gray" style={{ textAlign: 'center' }}>
            Email Changed To
          </th>
        </tr>
      </thead>
      <tbody>
        {userModel.emailHistory.map((history, index) => (
          <tr key={history.date}>
            <td id={`emailChangeDate.${index}`}>{history.date}</td>
            <td id={`emailChangeFrom.${index}`}>{history.emailChangedFrom}</td>
            <td id={`emailChangeTo.${index}`}>{history.emailChangedTo}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="grid--9 clearfix">
      <hr className="divider" />
      <h3 className="title">{t('User_Profile')}</h3>
      <table className="table-list">
        <tbody>
          <tr>
            <th align="left">
              <span className="text--label">{t('UID')}</span>
            </th>
            <td id="detail.uid">{userModel.uid}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Email')}</span>
            </th>
            <td id="detail.email">{userModel.email}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Username')}</span>
            </th>
            <td id="detail.userAlias">{userModel.userAlias}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('First_Name')}</span>
            </th>
            <td id="detail.firstName">{userModel.firstName}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Last_Name')}</span>
            </th>
            <td id="detail.lastName">{userModel.lastName}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Full_Name')}</span>
            </th>
            <td id="detail.fullName">{userModel.fullName}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Industry')}</span>
            </th>
            <td id="detail.industry">{t(userModel.industry)}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Department')}</span>
            </th>
            <td id="detail.department">{t(userModel.department)}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Job_Role')}</span>
            </th>
            <td id="detail.employeeType">{t(userModel.jobRole)}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Title')}</span>
            </th>
            <td id="detail.jobRole">{userModel.title}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Company')}</span>
            </th>
            <td id="detail.company">{userModel.company}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Phone')}</span>
            </th>
            <td id="detail.phone">{userModel.phone}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Country/Region')}</span>
            </th>
            <td id="detail.country">{country && country.label}</td>
          </tr>
          {addressLabels.administrativearea && (
            <tr>
              <th align="left">
                <span className="text--label">{addressLabels.administrativearea}</span>
              </th>
              <td id="detail.state">{administrativearea}</td>
            </tr>
          )}
          {addressLabels.localityname && (
            <tr>
              <th align="left">
                <span className="text--label">{addressLabels.localityname}</span>
              </th>
              <td id="detail.locality">{userModel.localityname}</td>
            </tr>
          )}
          {addressLabels.dependent_localityname && (
            <tr>
              <th align="left">
                <span className="text--label">{addressLabels.dependent_localityname}</span>
              </th>
              <td id="detail.dependent_localityname">{userModel.dependent_localityname}</td>
            </tr>
          )}
          {addressLabels.thoroughfare && (
            <tr>
              <th align="left">
                <span className="text--label">{addressLabels.thoroughfare}</span>
              </th>
              <td id="detail.thoroughfare">{userModel.thoroughfare}</td>
            </tr>
          )}
          {addressLabels.premise && (
            <tr>
              <th align="left">
                <span className="text--label">{addressLabels.premise}</span>
              </th>
              <td id="detail.premise">{userModel.premise}</td>
            </tr>
          )}
          {addressLabels.postalcode && (
            <tr>
              <th align="left">
                <span className="text--label">{addressLabels.postalcode}</span>
              </th>
              <td id="detail.postal">{userModel.postalcode}</td>
            </tr>
          )}
          <tr>
            <th align="left">
              <span className="text--label">{t('Member_of_Groups')}</span>
            </th>
            <td id="detail.group">{userModel.memberOfGroups}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Status')}</span>
            </th>
            <td id="detail.status">{t(capitalize.words(userModel.status))}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Preferred_Language')}</span>
            </th>
            <td id="detail.language">{userModel.language && `${language()} (${userModel.language})`}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Last_Login')}</span>
            </th>
            <td id="detail.lastLogin">{userModel.lastLogin}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Created_Timestamp')}</span>
            </th>
            <td id="detail.createdDate">{userModel.created}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Modified_Timestamp')}</span>
            </th>
            <td id="detail.updatedDate">{userModel.updated}</td>
          </tr>
          <tr>
            <th align="left">
              <span className="text--label">{t('Email_Change_History')}</span>
            </th>
            <td id="detail.emailHistory">{userModel.emailHistory && <EmailChangeHistoryTable />}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

UserDetailView.propTypes = {
  t: PropTypes.func.isRequired,
  userModel: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};
export default translate()(UserDetailView);
