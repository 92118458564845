import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import PropTypes from "prop-types";

import { LocalStorageKeys, MessageType } from "../common/Constants";
import { translateArray } from "../common/Util";
import AdditionalInfoForm from "../components/forms/AdditionalInfoForm";
import LoaderOverlay from "../components/LoaderOverlay";
import Message from "../components/Message";
import * as UserActions from "../redux/actions/userActions";
import LocalStorage from "../common/LocalStorage";
import PublicErrorHandling from "../components/PublicErrorHandling";

import { logInfo } from "../common/Logger";

export class AdditionalInfo extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    const params = new URLSearchParams(window.location.search);
    this.auth0State = params.get("state") || "";
    this.uid = params.get("uid") || "";
    this.country = params.get("country") || "";
    this.token = params.get("token") || "";
    this.hashedEmail = params.get("email") || "";
    this.loggedLanding = false;
    LocalStorage.setItem(LocalStorageKeys.publicSession, true);
    LocalStorage.setItem(LocalStorageKeys.token, this.token);
    LocalStorage.setItem(LocalStorageKeys.hashedEmail, this.hashedEmail);
    document.title = this.props.t("Tableau Account Registration");
    this.logLanding()
  }

  logLanding() {
    if (!this.loggedLanding) {
      this.loggedLanding = true;
      logInfo({
        uid: this.hashedEmail,
        eventType: "enteredAdditionalInfoPage",
        message: `User with UID ${this.uid} and hashedEmail ${this.hashedEmail} entered the Additional Info page`
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.updateSuccess && this.props.updateSuccess) {
      logInfo({
        uid: this.hashedEmail,
        eventType: "leftAdditionalInfoPage",
        message: `User with UID ${this.uid} and hashedEmail ${this.hashedEmail} left the Additional Info page`
      });
      window.history.replaceState({}, "Tableau", "/");
      window.location.href = `https://${window.REACT_APP_DOMAIN}/continue?state=${this.auth0State}`;
    }
  }

  onSubmit = user => {
    window.user = user;
    this.props.i18n.changeLanguage(user.language);
    this.props.updateUser(this.uid, user, this.props.i18n.language);
  };

  render() {
    const {
      isFetching,
      updateUserError,
      updateUserRequestId,
      userModel,
      t
    } = this.props;

    userModel.country = this.country;

    const isAlreadyUpdatedError = error => error[0].includes("already");
    const updateErrorMessage = "Invalid_request._Please_try_again";
    if (updateUserError) {
      if (updateUserError[0].includes("authorized")) {
        updateUserError[0] = updateErrorMessage;
      } else if (isAlreadyUpdatedError(updateUserError)) {
        window.location.href = `https://${window.REACT_APP_DOMAIN}/continue?state=${this.auth0State}`;
      }
    }

    const pageInfo = () => (
      <p className="text--tableau-desktop caption">
        {`* ${t(
          "These_preferences_will_be_stored_with_your_account_and_you_wont_be_prompted_again_on_subsequent_log-ins."
        )}`}
      </p>
    );

    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay />}
          {updateUserError && !isAlreadyUpdatedError(updateUserError) && (
            <Message
              id="errorMessage"
              text={translateArray(updateUserError)}
              messageType={MessageType.error}
            />
          )}
          {updateUserError && !isAlreadyUpdatedError(updateUserError) && (
            <PublicErrorHandling id="publicRedirectMessage" />
          )}
          {!updateUserError && userModel && (
            <AdditionalInfoForm
              isFetching={isFetching}
              onSubmit={this.onSubmit}
              userModel={userModel}
            />
          )}
          {!updateUserError && userModel && pageInfo()}
        </div>
        <input
          type="hidden"
          id="updateUserRequestId"
          value={updateUserRequestId}
        />
      </section>
    );
  }
}

AdditionalInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateSuccess: PropTypes.bool,
  updateUser: PropTypes.func.isRequired,
  updateUserError: PropTypes.array,
  updateUserRequestId: PropTypes.string,
  userModel: PropTypes.object
};

AdditionalInfo.defaultProps = {
  isFetching: false,
  updateSuccess: false,
  updateUserError: null,
  updateUserRequestId: "",
  userModel: {}
};

const mapStateToProps = state => ({
  isFetching:
    state.userGetReducer.isFetching || state.userUpdateReducer.isFetching,
  updateSuccess: state.userUpdateReducer.success,
  updateUserError: state.userUpdateReducer.errorMessage,
  updateUserRequestId: state.userUpdateReducer.requestId,
  userModel: state.userUpdateReducer.userModel || state.userGetReducer.userModel
});

const mapDispatchToProps = dispatch => ({
  updateUser: bindActionCreators(UserActions.updateUser, dispatch)
});

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo)
);
