import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { LOCATION_CHANGE } from 'react-router-redux';
import { UserActionTypes, Headers, ErrorCode } from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';

function getErrorMessage(error) {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.UNAUTHORIZED:
      case ErrorCode.FORBIDDEN:
        errorMessage = ["We're_sorry._You_are_not_authorized_to_access_the_requested_page_or_URL."];
        break;
      case ErrorCode.INVALID_EMAIL:
        errorMessage = ['The_Email_you_supplied_was_invalid.'];
        break;
      case ErrorCode.AUTH0_TIMEOUT_ERROR:
        errorMessage = ['The query exceeded the timeout. Please try again.'];
        break;
      case ErrorCode.AUTH0_CANNOT_PARSE_ERROR:
        errorMessage = ['Invalid query.  Auth0 could not parse the query.'];
        break;
      case ErrorCode.NOT_FOUND:
        errorMessage = ['User $email is not blocked.'];
        break;
      default:
        errorMessage = ["Were_sorry_An_unknown_error_has_occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["Were_sorry_An_unknown_error_has_occurred."];
  }

  return errorMessage;
}


export default function reducer(
  state = {
    errorMessage: null,
    unblockSuccess: false,
    isFetching: false,
    requestId: '',
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {};
    }
    case `${UserActionTypes.UNBLOCK_USER}_${PENDING}`: {
      return {
        ...state,
        isFetching: true,
        unblockSuccess: false,
        requestId: '',
      };
    }
    case `${UserActionTypes.UNBLOCK_USER}_${FULFILLED}`: {
      const unblockSuccess = action.payload.status === 200;

      return {
        ...state,
        isFetching: false,
        unblockSuccess,
        errorMessage: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${UserActionTypes.UNBLOCK_USER}_${REJECTED}`: {
      const errorMessage = getErrorMessage(action.payload);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        unblockSuccess: false,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }

    default:
      return state;
  }
}
