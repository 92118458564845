import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { MessageType, TokenTypes } from "../common/Constants";
import * as TokenActions from "../redux/actions/tokenActions";
import * as Util from "../common/Util";
import LoaderOverlay from "../components/LoaderOverlay";
import Message from "../components/Message";
import TokenForm from "../components/forms/TokenForm";
import ResendToken from "./ResendToken";

export class Token extends Component {
  constructor(props) {
    super(props);
    this.tokenId = props.match.params.tokenId;
    this.fetchToken = this.props.tokenActions.fetchToken;
    document.title = "Tableau";
  }

  componentDidMount() {
    this.fetchToken(this.tokenId);
    window.history.replaceState({}, "Tableau", "/token");
  }

  patchToken = body => {
    const { tokenActions, tokenType, i18n } = this.props;
    tokenActions.patchToken(this.tokenId, tokenType, body, i18n.language);
  };

  render() {
    const {
      errorMessage,
      expiredMessage,
      isFetching,
      patchSuccessMessage,
      profileActiveMessage,
      requiresInput,
      requiresResendForm,
      t,
      tokenType,
      tokenUsedMessage,
      requestId,
      activationTitle,
      activationDetails,
      hasName
    } = this.props;

    return (
      <div>
        {!requiresResendForm && (
          <section className="section">
            <div className="content-container" id="loading-overlay-content">
              <div>
                {isFetching && <LoaderOverlay />}
                {patchSuccessMessage && (
                  <Message
                    id="successMessage"
                    text={Util.translateArray(patchSuccessMessage)}
                    messageType={MessageType.notice}
                  />
                )}
                {profileActiveMessage && (
                  <Message
                    id="alreadyActiveMessage"
                    text={t(profileActiveMessage)}
                    messageType={MessageType.notice}
                  />
                )}
                {expiredMessage && (
                  <Message
                    id="expiredMessage"
                    text={Util.translateArray(expiredMessage)}
                    messageType={MessageType.error}
                  />
                )}
                {tokenUsedMessage && (
                  <Message
                    id="tokenUsedMessage"
                    text={t(tokenUsedMessage)}
                    messageType={MessageType.error}
                  />
                )}
                {patchSuccessMessage === null && errorMessage && (
                  <Message
                    id="errorMessage"
                    text={Util.translateArray(errorMessage)}
                    messageType={MessageType.error}
                  />
                )}
              </div>

              {(requiresInput ||
                (patchSuccessMessage === null &&
                  errorMessage &&
                  errorMessage.includes(
                    "This_password_has_previously_been_used._Try_another."
                  ))) && (
                <TokenForm
                  tokenType={tokenType}
                  activationTitle={activationTitle}
                  activationDetails={activationDetails}
                  onSubmit={this.patchToken}
                  isFetching={isFetching}
                  hasName={hasName}
                />
              )}
            </div>
          </section>
        )}

        {requiresResendForm && tokenType === TokenTypes.RESET_PASSWORD && (
          <ResendToken
            tokenType={TokenTypes.RESET_PASSWORD}
            externalError={Util.translateArray(errorMessage)}
          />
        )}

        {requiresResendForm && tokenType !== TokenTypes.RESET_PASSWORD && (
          <ResendToken
            tokenType={TokenTypes.RESEND_ACTIVATION}
            externalError={Util.translateArray(errorMessage)}
          />
        )}
        <input type="hidden" id="requestId" value={requestId} />
      </div>
    );
  }
}
Token.propTypes = {
  errorMessage: PropTypes.array,
  expiredMessage: PropTypes.array,
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  match: PropTypes.object.isRequired,
  patchSuccessMessage: PropTypes.array,
  profileActiveMessage: PropTypes.string,
  requiresInput: PropTypes.bool,
  requiresResendForm: PropTypes.bool,
  t: PropTypes.func.isRequired,
  tokenActions: PropTypes.object.isRequired,
  tokenType: PropTypes.string,
  tokenUsedMessage: PropTypes.string,
  requestId: PropTypes.string,
  activationTitle: PropTypes.string,
  activationDetails: PropTypes.string,
  hasName: PropTypes.bool
};

Token.defaultProps = {
  errorMessage: null,
  expiredMessage: null,
  isFetching: false,
  patchSuccessMessage: null,
  profileActiveMessage: null,
  requiresInput: false,
  requiresResendForm: false,
  tokenType: null,
  tokenUsedMessage: null,
  requestId: "",
  activationTitle: null,
  activationDetails: null,
  hasName: false
};

export const mapStateToProps = state => ({
  errorMessage: state.tokenReducer.errorMessage,
  expiredMessage: state.tokenReducer.expiredMessage,
  isFetching: state.tokenReducer.isFetching,
  patchSuccessMessage: state.tokenReducer.patchSuccessMessage,
  postSuccessMessage: state.tokenReducer.postSuccessMessage,
  profileActiveMessage: state.tokenReducer.profileActiveMessage,
  requiresInput: state.tokenReducer.requiresInput,
  requiresResendForm: state.tokenReducer.requiresResendForm,
  tokenType: state.tokenReducer.tokenType,
  tokenUsedMessage: state.tokenReducer.tokenUsedMessage,
  requestId: state.tokenReducer.requestId,
  activationTitle: state.tokenReducer.activationTitle,
  activationDetails: state.tokenReducer.activationDetails,
  hasName: state.tokenReducer.hasName
});

const mapDispatchToProps = dispatch => ({
  tokenActions: bindActionCreators(TokenActions, dispatch)
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(Token));
