/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 19:18:38
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-14 12:47:29
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

import { MANAGEACCOUNT_ROUTE } from '../../routes';
import {
  UserActionTypes, Headers, ErrorMessages, ErrorCode,
} from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';
import * as UserModel from '../../models/UserModel';

function getErrorMessage(error) {
  let errorMessage = null;
  const fieldError = [
    'The following required fields were missing or contained invalid data.',
    'Please try again.',
    '<br /><br /> &bull; ',
  ];
  if (error.response) {
    const requestId = getHeaderFromPayLoad(error, Headers.requestId);
    switch (error.response.data.code) {
      case 'ADMIN_GROUPS_INVALID':
        errorMessage = [
          'Update group membership failed. Only Profile Admin can have With Email or With Permission Grant.',
        ];
        break;
      case ErrorCode.FORBIDDEN_UPDATE_GROUPS:
        errorMessage = ['Update user failed, not authorized to set groups.'];
        break;
      case ErrorCode.ADMIN_GROUPS_TABLEAU_ONLY:
        errorMessage = ['Admin groups are only allowed for Tableau accounts.'];
        break;
      case ErrorCode.INVALID_LAST_NAME:
        fieldError.push(ErrorMessages.lastName);
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_FIRST_NAME:
        fieldError.push(ErrorMessages.firstName);
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_PHONE_NUMBER:
        fieldError.push('Phone');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_COUNTRY:
        fieldError.push('Country');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_STATE:
        fieldError.push('State');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_COMPANY:
        fieldError.push('Company');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_TITLE:
        fieldError.push('Title');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_DEPARTMENT:
        fieldError.push('Department');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_INDUSTRY:
        fieldError.push('Industry');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_JOB_ROLE:
        fieldError.push('Job Role');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_LANGUAGE:
        fieldError.push('Language');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_THOROUGHFARE:
        fieldError.push('Thoroughfare');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_PREMISE:
        fieldError.push('Premise');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_LOCALITY_NAME:
        fieldError.push('Locality Name');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_ADMINISTRATIVE_AREA:
        fieldError.push('Administrative Area');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_POSTAL_CODE:
        fieldError.push('Postal Code');
        errorMessage = fieldError;
        break;
      case ErrorCode.INVALID_USER_ALIAS:
        fieldError.push(ErrorMessages.userAlias);
        errorMessage = fieldError;
        break;
      case ErrorCode.USER_ALIAS_ALREADY_EXISTS:
        errorMessage = ['Username_not_available._Please_choose_a_different_username.'];
        break;
      case ErrorCode.UNAUTHORIZED:
      case ErrorCode.FORBIDDEN:
        errorMessage = ["We're_sorry._You_are_not_authorized_to_access_the_requested_page_or_URL."];
        break;
      case ErrorCode.USER_ALREADY_UPDATED:
        errorMessage = ["User is already updated."]
        break;
      default:
        console.log(ErrorCode.USER_ALREADY_UPDATED);
        errorMessage = ["Were_sorry_An_unknown_error_has_occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["Were_sorry_An_unknown_error_has_occurred."];
  }

  return errorMessage;
}

export default function reducer(
  state = {
    errorMessage: null,
    success: false,
    isFetching: false,
    userModel: null,
    validGoto: null,
    requestId: '',
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (action.payload.pathname === MANAGEACCOUNT_ROUTE) {
        return { ...state, errorMessage: null, success: false };
      }
      return {};
    }
    case `${UserActionTypes.UPDATE_USER}_${PENDING}`: {
      return {
        ...state,
        isFetching: true,
        searchResults: null,
        requestId: '',
      };
    }
    case `${UserActionTypes.UPDATE_USER}_${FULFILLED}`: {
      const userModel = UserModel.mapUser(action.payload.data);
      const validGoto = action.payload.headers[Headers.validGoto];
      return {
        ...state,
        isFetching: false,
        userModel,
        success: true,
        errorMessage: null,
        validGoto,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${UserActionTypes.UPDATE_USER}_${REJECTED}`: {
      const errorMessage = getErrorMessage(action.payload);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        success: false,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }

    default:
      return state;
  }
}
