import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import MyUserProfileForm from '../components/forms/MyUserProfileForm';
import { getProfile } from '../common/SessionManager';
import * as UserActions from '../redux/actions/userActions';
import Message from '../components/Message';
import LoaderOverlay from '../components/LoaderOverlay';
import { MessageType } from '../common/Constants';
import { translateArray } from '../common/Util';

class MyUserProfile extends Component {
  constructor(props) {
    super(props);
    document.title = this.props.t('Tableau_-_Account_Management');
    this.uid = getProfile().uid;
    this.props.getUser(this.uid);
  }

  componentDidUpdate() {
    // Scroll to the top of the page after updating
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  onSubmit = (user) => {
    window.user = user;
    this.props.i18n.changeLanguage(user.language);
    this.props.updateUser(user.uid, user, this.props.i18n.language, this.props.goto);
  };

  render() {
    const {
      t,
      userModel,
      updateSuccess,
      getUserError,
      updateUserError,
      isFetching,
      goto,
      validGoto,
      getUserRequestId,
      updateUserRequestId,
    } = this.props;

    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay />}
          {getUserError && (
            <Message id="errorMessage" text={translateArray(getUserError)} messageType={MessageType.error} />
          )}
          {updateUserError && (
            <Message id="errorMessage" text={translateArray(updateUserError)} messageType={MessageType.error} />
          )}
          {updateSuccess && !validGoto && (
            <Message
              id="successMessage"
              text={t('Your_profile_was_successfully_updated.')}
              messageType={MessageType.notice}
            />
          )}
          {updateSuccess && validGoto && (
            <Message
              id="successMessage"
              text={translateArray([
                'Your_profile_was_successfully_updated.',
                () => t('You_may_now_continue_to_a_href_link_link_a_.', { link: goto }),
              ])}
              messageType={MessageType.notice}
            />
          )}
          {userModel && <MyUserProfileForm onSubmit={this.onSubmit} userModel={userModel} isFetching={isFetching} />}
        </div>
        <input type="hidden" id="getUserRequestId" value={getUserRequestId} />
        <input type="hidden" id="updateUserRequestId" value={updateUserRequestId} />
      </section>
    );
  }
}
MyUserProfile.propTypes = {
  t: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  userModel: PropTypes.object,
  updateSuccess: PropTypes.bool,
  getUserError: PropTypes.array,
  updateUserError: PropTypes.array,
  isFetching: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  goto: PropTypes.string,
  validGoto: PropTypes.string,
  getUserRequestId: PropTypes.string,
  updateUserRequestId: PropTypes.string,
};

MyUserProfile.defaultProps = {
  updateSuccess: false,
  getUserError: null,
  updateUserError: null,
  isFetching: false,
  userModel: null,
  goto: null,
  validGoto: null,
  getUserRequestId: '',
  updateUserRequestId: '',
};

const mapStateToProps = state => ({
  getUserError: state.userGetReducer.errorMessage,
  updateUserError: state.userUpdateReducer.errorMessage,
  updateSuccess: state.userUpdateReducer.success,
  isFetching: state.userGetReducer.isFetching || state.userUpdateReducer.isFetching,
  userModel: state.userUpdateReducer.userModel || state.userGetReducer.userModel,
  goto: state.locationReducer.goto,
  validGoto: state.userUpdateReducer.validGoto,
  getUserRequestId: state.userGetReducer.requestId,
  updateUserRequestId: state.userUpdateReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  getUser: bindActionCreators(UserActions.getUser, dispatch),
  updateUser: bindActionCreators(UserActions.updateUser, dispatch),
});

export default translate()(connect(mapStateToProps, mapDispatchToProps)(MyUserProfile));
