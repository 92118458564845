import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ValidationInput from './elements/ValidationInput';
import Input from './elements/Input';
import ValidationTextArea from './elements/ValidationTextArea';

class HelpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDisabled: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => ({
    name: inputs.name,
    email: inputs.email,
    company: inputs.company,
    description: inputs.description,
  });

  render() {
    const { t } = this.props;

    return (
      <div id="form" className="clearfix">
        <h1 className="title">{t('Having_a_problem_with_your_account')}</h1>
        <p>
          {t(
            'If_you_are_having_difficulty_signing_in_you_can_reset_your_password_using_the_link_above._Otherwise_please_use_the_form_below_to_ask_us_a_question_or_tell_us_about_your_problem_and_we_will_respond_as_soon_as_we_can.',
          )}
        </p>
        <Formsy
          className="form--full-width"
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <div className="clearfix">
            <div className="grid--4">
              <ValidationInput
                autoFocus
                id="name"
                maxLength="40"
                name="name"
                placeholder={t('Name')}
                type="text"
                required
                value=""
                validationErrors={{
                  isDefaultRequiredValue: t('Please_provide_your_name.'),
                }}
                title={t('Name')}
              />
              <ValidationInput
                id="email"
                maxLength="80"
                name="email"
                placeholder={t('Email')}
                type="email"
                required
                value=""
                validations="isEmail"
                validationErrors={{
                  isDefaultRequiredValue: t('This_field_is_required.'),
                }}
                validationError={t('Please_enter_a_valid_email_address.')}
                title={t('Email')}
              />
              <Input
                id="company"
                maxLength="100"
                name="company"
                placeholder={t('Company_Optional')}
                type="text"
                title={t('Company')}
                value=""
              />
            </div>
          </div>
          <div className="clearfix">
            <div className="grid--8">
              <ValidationTextArea
                id="description"
                maxLength="1000"
                name="description"
                required
                value=""
                validationErrors={{
                  isDefaultRequiredValue: t('Please_provide_a_question_or_issue_before_submitting.'),
                }}
                title={t('Question_or_Issue')}
              />
            </div>
          </div>
          <div className="form-buttons">
            <button type="submit" className="cta cta--secondary" id="helpButton" disabled={this.state.submitDisabled}>
              {t('Submit')}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

HelpForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
export default translate()(HelpForm);
