/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 19:18:38
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-14 12:46:59
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

import { MANAGEACCOUNT_ROUTE } from '../../routes';
import { UserActionTypes, Headers, ErrorCode } from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';
import * as UserModel from '../../models/UserModel';

function getErrorMessage(error) {
  let errorMessage = null;
  if (error.response) {
    const requestId = getHeaderFromPayLoad(error, Headers.requestId);
    switch (error.response.data.code) {
      case ErrorCode.UNAUTHORIZED:
      case ErrorCode.FORBIDDEN:
        errorMessage = ["We're_sorry._You_are_not_authorized_to_access_the_requested_page_or_URL."];
        break;
      default:
        errorMessage = ["Were_sorry_An_unknown_error_has_occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["Were_sorry_An_unknown_error_has_occurred."];
  }

  return errorMessage;
}

export default function reducer(
  state = {
    errorMessage: null,
    isFetching: false,
    userModel: null,
    requestId: '',
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (action.payload.pathname === MANAGEACCOUNT_ROUTE) {
        return { ...state };
      }
      return {};
    }
    case `${UserActionTypes.GET_USER}_${PENDING}`: {
      return {
        ...state,
        isFetching: true,
        searchResults: null,
        requestId: '',
      };
    }
    case `${UserActionTypes.GET_USER}_${FULFILLED}`: {
      const userModel = UserModel.mapUser(action.payload.data);
      return {
        ...state,
        isFetching: false,
        userModel,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${UserActionTypes.GET_USER}_${REJECTED}`: {
      const errorMessage = getErrorMessage(action.payload);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        successMessage: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }

    default:
      return state;
  }
}
