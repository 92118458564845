import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { HOME_ROUTE } from '../../routes';
import { ErrorMessages } from '../../common/Constants';
import ValidationInput from './elements/ValidationInput';
import Input from './elements/Input';

class RegistrationForm extends Component {
  constructor() {
    super();
    this.state = { submitDisabled: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => ({
    firstName: inputs.firstName,
    lastName: inputs.lastName,
    email: inputs.email,
    honeyPot: inputs.e__mail,
    password: inputs.password,
  });

  render() {
    const { t } = this.props;

    return (
      <Formsy
        className="form--full-width"
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.onInvalidSubmit}
        mapping={this.mapInputs}
        noValidate
      >
        <div className="content-container clearfix">
          <div className="grid--2">&nbsp;</div>
          <div className="grid--7 grid--last" style={{ marginLeft: '4.3%' }}>
            <ValidationInput
              autoFocus
              id="firstName"
              maxLength="40"
              name="firstName"
              placeholder={t('First_Name')}
              type="text"
              required
              value=""
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
              validations="validName"
              validationError={t(ErrorMessages.firstName)}
              title={t('First_Name')}
            />
            <ValidationInput
              id="lastName"
              maxLength="80"
              name="lastName"
              placeholder={t('Last_Name')}
              type="text"
              required
              value=""
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
              validations="validName"
              validationError={t(ErrorMessages.lastName)}
              title={t('Last_Name"')}
            />
            <ValidationInput
              id="email"
              maxLength="80"
              name="email"
              placeholder={t('Email')}
              type="email"
              required
              value=""
              validations="isEmail"
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
              validationError={t('Please_enter_a_valid_email_address.')}
              title={t('Email')}
            />
            <ValidationInput
              id="password"
              maxLength="100"
              name="password"
              placeholder={t('Password')}
              type="password"
              required
              value=""
              validations="validPassword"
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
              validationError={t(
                'Passwords_must_contain_at_least_one_number_one_uppercase_letter_one_lowercase_letter_one_special_character_and_be_at_least_8_characters_long.',
              )}
              title={t('Password')}
            />
            <ValidationInput
              id="confirmPassword"
              maxLength="100"
              name="confirmPassword"
              placeholder={t('Confirm_Password')}
              type="password"
              required
              value=""
              validations="equalsField:password"
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
              validationError={t('This_must_match_the_Password_field_above.')}
              title={t('Confirm_Password')}
            />
            <div className="form-field hidden">
              <label htmlFor="e__mail" className="form-field__label">
                {t('Screened')}
                :
                <Input id="e__mail" name="e__mail" title="Email" placeholder="Email" />
              </label>
            </div>
            <p>
              {t(
                'Passwords_are_case_sensitive_8-100_characters_long._Requires_at_least_one_number_0-9_one_uppercase_letter_one_lowercase_letter_and_one_of_the_following_characters:_$_&_-_._:___.',
              )}
            </p>
            <div className="form-buttons text--centered margin-top-two">
              <button
                type="submit"
                className="cta cta--secondary"
                id="registerButton"
                disabled={this.state.submitDisabled}
              >
                {t('Create_My_Account')}
              </button>
              <div className="help-link link margin-top-two">
                <span className="text--dark-body" style={{ paddingRight: '10px' }}>
                  {t('Already_have_an_account')}
                </span>
                <Link to={HOME_ROUTE} id="alreadyHaveAccountLink">
                  {t('Sign_in')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Formsy>
    );
  }
}

RegistrationForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
export default translate()(RegistrationForm);
