import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { searchUsers } from '../redux/actions/userActions';
import { postEmailToken } from '../redux/actions/tokenActions';
import SubmitEmailForm from '../components/forms/SubmitEmailForm';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import { translateArray } from '../common/Util';
import { MessageType } from '../common/Constants';
import { getChangeEmailErrorMessage } from '../common/MessageUtil';

export class ChangeEmail extends Component {
  constructor(props) {
    super(props);
    this.successMessage = 'Your_request_has_been_processed._In_order_to_maintain_the_security_of_the_account_you_will_receive_an_email_containing_a_verification_code_and_a_link_that_will_allow_you_to_update_your_email_address._Follow_the_instructions_in_the_email_in_order_to_update_your_account_to_the_new_email_address.';
    this.userNotFoundMessage = ['Email_address_not_found.', 'Please_try_again.'];
    this.userIsOnlineUserMessage = ' is a Tableau Cloud user, and their email account cannot be changed. <br/>For more information, please refer to <a href="https://kb.tableau.com/articles/howto/changing-tableau-cloud-user-name">https://kb.tableau.com/articles/howto/changing-tableau-cloud-user-name</a>.'
  }
  // to do find this string.

  componentDidMount() {
    document.title = `Tableau - ${this.props.t('Change_Email')}`;
    if (this.props.emailQueryString) {
      this.props.findUser({
        email: this.props.emailQueryString,
        emailChangeHistory: false,
      });
    }
  }

  render() {
    const {
      t,
      findUser,
      changeEmail,
      success,
      changeEmailError,
      isFetching,
      user,
      i18n,
      notFound,
      searchError,
      searchRequestId,
      changeEmailRequestId,
      onlineUser,
    } = this.props;

    const findByEmail = (formData) => {
      findUser({ email: formData.email, emailChangeHistory: false });
    };
    const onSubmit = (formData) => {
      changeEmail(user.uid, formData.email, i18n.language);
    };
    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay />}
          {searchError && (
            <Message id="errorMessage" text={translateArray(searchError)} messageType={MessageType.error} />
          )}
          {changeEmailError && (
            <Message
              id="errorMessage"
              text={translateArray(getChangeEmailErrorMessage(changeEmailError))}
              messageType={MessageType.error}
            />
          )}
          {notFound && (
            <Message
              id="userNotFound"
              text={translateArray(this.userNotFoundMessage)}
              messageType={MessageType.error}
            />
          )}
          {onlineUser && user && (
              <Message
                  id="userIsOnlineUser"
                  text={t('<b>' + user.email + '</b>' + this.userIsOnlineUserMessage)}
                  messageType={MessageType.warning}
              />
          )}
          {success && <Message id="successMessage" text={t(this.successMessage)} messageType={MessageType.notice} />}
          {!onlineUser && !user && !success && (
            <SubmitEmailForm
              autoFocus
              id="oldEmailForm"
              title={t('Change_Email')}
              buttonText={t('Next')}
              buttonClass="cta cta--secondary cta--arrow"
              inputTitle={t('Current_Email')}
              onSubmit={findByEmail}
              noValidate
              value=""
              buttonId="changeEmailNextButton"
              isFetching={isFetching}
            />
          )}
          {!onlineUser && user && !success && (
            <SubmitEmailForm
              autoFocus
              id="newEmailForm"
              title={t('Change_User_Profile_Email')}
              subTitle={`${t('Name')}: ${user.fullName}`}
              additionalInfo={`${t('Email')}: ${user.email}`}
              buttonText={t('Change_Email')}
              buttonId="changeEmailButton"
              inputTitle={t('New_Email')}
              onSubmit={onSubmit}
              isFetching={isFetching}
              noValidate
            />
          )}
        </div>
        <input type="hidden" id="changeEmailRequestId" value={changeEmailRequestId} />
        <input type="hidden" id="searchRequestId" value={searchRequestId} />
      </section>
    );
  }
}
ChangeEmail.propTypes = {
  t: PropTypes.func.isRequired,
  success: PropTypes.bool,
  changeEmailError: PropTypes.object,
  isFetching: PropTypes.bool,
  changeEmail: PropTypes.func.isRequired,
  findUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  notFound: PropTypes.bool,
  searchError: PropTypes.array,
  emailQueryString: PropTypes.string,
  searchRequestId: PropTypes.string,
  changeEmailRequestId: PropTypes.string,
  onlineUser: PropTypes.bool,
};

ChangeEmail.defaultProps = {
  changeEmailError: null,
  success: false,
  isFetching: false,
  user: null,
  notFound: false,
  searchError: null,
  emailQueryString: null,
  searchRequestId: '',
  changeEmailRequestId: '',
  onlineUser: false,
};

export const mapStateToProps = state => ({
  searchError: state.userSearchReducer.errorMessage,
  user: state.userSearchReducer.userModel,
  notFound: state.userSearchReducer.notFound,
  success: state.changeEmailReducer.success,
  changeEmailError: state.changeEmailReducer.error,
  isFetching: state.changeEmailReducer.isFetching || state.userSearchReducer.isFetching,
  emailQueryString: state.locationReducer.email,
  searchRequestId: state.userSearchReducer.requestId,
  changeEmailRequestId: state.changeEmailReducer.requestId,
  onlineUser: state.userSearchReducer.onlineUser,
});

const mapDispatchToProps = dispatch => ({
  findUser: bindActionCreators(searchUsers, dispatch),
  changeEmail: bindActionCreators(postEmailToken, dispatch),
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(ChangeEmail));
