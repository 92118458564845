import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CHANGEEMAIL_ROUTE } from '../../routes';
import { UserStatusTypes, UserGroups, ErrorMessages } from '../../common/Constants';
import * as Data from '../../common/Data';
import * as UserModel from '../../models/UserModel';
import Checkbox from './elements/Checkbox';
import Input from './elements/Input';
import RadioGroup from './elements/RadioGroup';
import Select from './elements/Select';
import ValidationInput from './elements/ValidationInput';
import AddressField from './elements/AddressField';
import getAddressConfig from './Data/AddressConfig';

class UserEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { config: getAddressConfig(this.props.i18n.language), submitDisabled: false };
    this.setStateFromLanguage = this.setStateFromLanguage.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.setStateFromLanguage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.setStateFromLanguage);
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  setStateFromLanguage(language) {
    this.setState({ config: getAddressConfig(language) });
  }

  mapInputs = inputs => UserModel.mapInputsFromUserEdit(this.props.userModel, inputs);

  render() {
    const {
      t, userModel, showGroups, showAdminGroups,
    } = this.props;
    let groupMembership = Data.GroupMembership;
    if (!showAdminGroups) {
      groupMembership = Data.GroupMembership.filter(
        group => group.value !== UserGroups.profileAdmins && group.value !== UserGroups.profileReaders,
      );
    }

    const status = [
      { id: '1', value: 'active', label: 'Active' },
      { id: '2', value: 'deactivated', label: 'Deactivated' },
    ];

    if (!userModel) {
      return null;
    }

    return (
      <div id="form" className="clearfix">
        <h1 className="title">{t('User_Status')}</h1>
        <Formsy
          className="form form--full-width"
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <div className="grid--5">
            <div className="form-field form__field">
              {userModel.status === UserStatusTypes.pending && (
                <label className="form-field__label" htmlFor="pending">
                  {t('Status')}
                  <span id="pending" className="label-tag">
                    {t('Pending_not_activated_email_not_verified')}
                  </span>
                </label>
              )}
              {userModel.status !== UserStatusTypes.pending && (
                <RadioGroup title={t('Status')} name="status" value={userModel.status} items={status} />
              )}
            </div>
            <div>
              {showGroups && (
                <RadioGroup
                  title={t('Group_Membership')}
                  name="group"
                  items={groupMembership}
                  value={userModel.group || ''}
                />
              )}
              {!showGroups && (
                <div className="form-field form__field">
                  <label htmlFor="membership" className="form-field__label">
                    {t('Group_Membership')}
                  </label>
                  <div className="label-tag">
                    {t('User_is_an_admin._Only_an_Admin_With_Permission_Grant_can_change_this_group_membership.')}
                  </div>
                </div>
              )}
              {showAdminGroups && (
                <div>
                  <Checkbox
                    id="emailViewers"
                    label={t('With_Email')}
                    name="emailViewers"
                    value={UserGroups.emailViewers}
                    checked={userModel.emailViewers === UserGroups.emailViewers}
                  />
                  <Checkbox
                    id="permissionGranters"
                    label={t('With_Permission_Grant')}
                    name="permissionGranters"
                    value={UserGroups.permissionGranters}
                    checked={userModel.permissionGranters === UserGroups.permissionGranters}
                  />
                </div>
              )}
            </div>

            <div className="form-field form__field">
              <label className="form-field__label" htmlFor="uid">
                {t('UID')}
              </label>
              <span className="label-tag label-tag--raw" id="uid">
                {' '}
                {userModel.uid}
              </span>
            </div>
            <div className="form-field form__field">
              <label className="form-field__label" htmlFor="userEmail">
                {t('Email')}
              </label>
              <span className="label-tag label-tag--raw label-tag--email" id="userEmail">
                {userModel.email}
                &nbsp;
                <a
                  href={`${CHANGEEMAIL_ROUTE}?email=${encodeURIComponent(userModel.email)}`}
                  className="btn btn-default btn-xs"
                  role="button"
                >
                  <span className="glyphicon glyphicon-edit">Edit</span>
                </a>
              </span>
            </div>
            <ValidationInput
              autoFocus
              id="userAlias"
              maxLength="100"
              name="userAlias"
              placeholder={t('Username')}
              type="text"
              value={userModel.userAlias || ''}
              title={t('Username')}
              validations="validUserAlias"
              validationError={t(ErrorMessages.userAlias)}
              required
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
            />
            <ValidationInput
              autoFocus
              id="firstName"
              maxLength="40"
              name="firstName"
              placeholder={t('First_Name')}
              type="text"
              value={userModel.firstName || ''}
              title={t('First_Name')}
              validations="validName"
              validationError={t(ErrorMessages.firstName)}
              required
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
            />
            <ValidationInput
              id="lastName"
              maxLength="80"
              name="lastName"
              placeholder={t('Last_Name')}
              type="text"
              value={userModel.lastName || ''}
              title={t('Last_Name')}
              validations="validName"
              validationError={t(ErrorMessages.lastName)}
              required
              validationErrors={{
                isDefaultRequiredValue: t('This_field_is_required.'),
              }}
            />
            <Input
              id="title"
              maxLength="100"
              name="title"
              placeholder={t('Title')}
              type="text"
              value={userModel.title || ''}
              title={t('Title')}
            />
            <Input
              id="company"
              maxLength="100"
              name="company"
              placeholder={t('Company')}
              type="text"
              value={userModel.company || ''}
              title={t('Company')}
            />
            <ValidationInput
              id="phone"
              maxLength="80"
              name="phone"
              placeholder={t('Phone')}
              type="text"
              value={userModel.phone || ''}
              validations="validPhone"
              validationError={t(ErrorMessages.phoneNumber)}
              title={t('Phone')}
            />
          </div>
          <div className="grid--2">&nbsp;</div>
          <div className="grid--5">
            <AddressField name="addressField" config={this.state.config} userModel={this.props.userModel} />
            <Select id="industry" name="industry" title="Industry" options={Data.industry} value={userModel.industry} />
            <Select
              id="department"
              name="department"
              title="Department"
              options={Data.department}
              value={userModel.department}
            />
            <Select id="jobRole" name="jobRole" title="Job Role" options={Data.jobrole} value={userModel.jobRole} />
            <div className="form-buttons">
              <button
                type="submit"
                className="cta cta--secondary"
                id="updateUserButton"
                disabled={this.state.submitDisabled}
              >
                {t('Update')}
              </button>
            </div>
          </div>
        </Formsy>
      </div>
    );
  }
}
UserEditForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showAdminGroups: PropTypes.bool,
  showGroups: PropTypes.bool,
  userModel: PropTypes.shape({
    company: PropTypes.string,
    country: PropTypes.string,
    created: PropTypes.string,
    department: PropTypes.string,
    email: PropTypes.string,
    emailViewers: PropTypes.string,
    employeeType: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    group: PropTypes.string,
    industry: PropTypes.string,
    jobRole: PropTypes.string,
    title: PropTypes.string,
    lastLogin: PropTypes.string,
    lastName: PropTypes.string,
    legacyGroup: PropTypes.string,
    memberOfGroups: PropTypes.string,
    permissionGranters: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string,
    uid: PropTypes.string,
    updated: PropTypes.string,
    userAlias: PropTypes.string,
    userId: PropTypes.string,
    verified: PropTypes.bool,
  }).isRequired,
};

UserEditForm.defaultProps = {
  showAdminGroups: false,
  showGroups: false,
};
export default translate()(UserEditForm);
