/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 19:19:27
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-11-27 22:21:11
 */
import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { LOCATION_CHANGE } from "react-router-redux";

import {
  TokenActionTypes,
  TokenTypes,
  Headers,
  ErrorCode
} from "../../common/Constants";
import { getHeaderFromPayLoad, isActivationToken } from "../../common/Util";
import i18n from "../../common/i18n";

const t = i18n.t.bind(i18n);
const tokenUsedOrDeletedMessage =
  "The_Url_or_code_requested_has_already_been_used.";

function processGetTokenReject(error) {
  let errorMessage;
  if (error.response) {
    const requestId = getHeaderFromPayLoad(error, Headers.requestId);
    switch (error.response.data.code) {
      case ErrorCode.NOT_FOUND: // Token does not exist
        errorMessage = [tokenUsedOrDeletedMessage];
        break;
      default:
        errorMessage = [
          "Were_sorry_An_unknown_error_has_occurred.",
          "<br />",
          `RequestId: ${requestId}`
        ];
        break;
    }
  } else {
    errorMessage = ["Were_sorry_An_unknown_error_has_occurred."];
  }
  return errorMessage;
}

function processPatchTokenReject(error, tokenType) {
  const result = { errorMessage: null, requiresResendForm: false };
  if (error.response) {
    const requestId = getHeaderFromPayLoad(error, Headers.requestId);
    switch (error.response.data.code) {
      case ErrorCode.TOKEN_ALREADY_USED: // Token already used.
        result.errorMessage = [tokenUsedOrDeletedMessage];
        break;
      case ErrorCode.USER_NOT_PENDING: // PATCH ERROR
        result.errorMessage = ["The_profile_was_already_activated."];
        break;
      case ErrorCode.TOKEN_EXPIRED: // Token Expired
        if (isActivationToken(tokenType)) {
          result.errorMessage = [
            "The_activation_has_expired._Please_use_the_form_below_to_send_a_new_activation_email."
          ];
        } else if (tokenType === TokenTypes.RESET_PASSWORD) {
          result.errorMessage = [
            "The_reset_password_request_has_expired._Please_use_the_form_below_to_send_a_new_password_reset_email."
          ];
        }
        result.requiresResendForm = true;
        break;
      case ErrorCode.UNAUTHORIZED:
      case ErrorCode.FORBIDDEN:
        result.errorMessage = [
          "We're_sorry._You_are_not_authorized_to_access_the_requested_page_or_URL."
        ];
        break;
      case ErrorCode.NOT_FOUND: // Token does not exist
        result.errorMessage = [tokenUsedOrDeletedMessage];
        break;
      case ErrorCode.PASSWORD_HISTORY_ERROR:
        result.errorMessage = [
          "This_password_has_previously_been_used._Try_another."
        ];
        break;

      default:
        result.errorMessage = [
          "Were_sorry_An_unknown_error_has_occurred.",
          "<br />",
          `RequestId: ${requestId}`
        ];
        break;
    }
  } else {
    result.errorMessage = ["Were_sorry_An_unknown_error_has_occurred."];
  }

  return result;
}

function getPatchSuccessMessage(token) {
  let result = null;
  if (isActivationToken(token.type)) {
    if (token.gotoUrl && !token.autoGoto) {
      result = [
        "Your_Tableau_account_was_successfully_activated.",
        () =>
          t('You_may_now_continue_to_a_href_link_link_a_.', {
            link: token.gotoUrl
          })
      ];
    } else {
      result = [
        "Your_Tableau_account_was_successfully_activated.",
        t('You_may_now_continue_to_a_href_link_link_a_.', {
          link: window.REACT_APP_BASE_URL
        })
      ];
    }
  } else if (token.type === TokenTypes.SALESFORCE_EMAIL_ACTIVATION) {
    result = [
      () => t('Your_Salesforce_account_was_successfully_linked_with_Tableau._You_may_now_use_Salesforce_Login_to_a_href_link_login_to_Tableau_a_.',
        { link: window.REACT_APP_BASE_URL }),
    ];
  } else if (token.type === TokenTypes.RESET_PASSWORD) {
    if (token.gotoUrl && !token.autoGoto) {
      result = [
        "Your_password_was_successfully_changed.",
        () =>
          t('You_may_now_continue_to_a_href_link_link_a_.', {
            link: token.gotoUrl
          })
      ];
    } else {
      result = ["Your_password_was_successfully_changed."];
    }
  } else if (token.type === TokenTypes.CHANGE_EMAIL) {
    result = [
      "Your_Tableau_account_has_successfully_been_updated_to_the_new_email_address."
    ];
  }

  return result;
}

function getExpiredMessage(token) {
  let result = null;
  if (isActivationToken(token.type) && !token.verified) {
    result = [
      "The_activation_has_expired._Please_use_the_form_below_to_send_a_new_activation_email."
    ];
  } else if (token.type === TokenTypes.RESET_PASSWORD) {
    result = [
      "The_reset_password_request_has_expired._Please_use_the_form_below_to_send_a_new_password_reset_email."
    ];
  } else if (token.type === TokenTypes.CHANGE_EMAIL) {
    result = [
      'This_change_email_request_has_expired._Please_submit_a_new_a_href_changeMyEmail_change_email_a_request.'
    ];
  }
  return result;
}

export default function reducer(
  state = {
    autoGoto: false,
    errorMessage: null,
    isFetching: false,
    patchSuccessMessage: null,
    profileActiveMessage: false,
    requiresInput: false,
    requiresResendForm: false,
    tokenType: null,
    tokenUsedMessage: null,
    requestId: ""
  },
  action
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {};
    }
    case `${TokenActionTypes.GET_TOKEN}_${PENDING}`: {
      return { ...state, isFetching: true, requestId: "" };
    }
    case `${TokenActionTypes.GET_TOKEN}_${FULFILLED}`: {
      const token = action.payload.data;

      if (!token.nameExist && !token.userVerified) {
        token.type = TokenTypes.EMAIL_ONLY_ACTIVATION;
      }

      const expiredMessage =
        !token.verified && token.expired ? getExpiredMessage(token) : null;
      const profileActiveMessage =
        (token.verified || token.userVerified) && isActivationToken(token.type)
          ? "The_profile_was_already_activated."
          : null;

      // If the token isn't an activate standard token and is veried, show a token already used error.
      const tokenUsedMessage =
        token.verified && !isActivationToken(token.type)
          ? tokenUsedOrDeletedMessage
          : null;
      let requiresInput =
        !token.verified &&
        (token.type === TokenTypes.EMAIL_ONLY_ACTIVATION ||
          token.type === TokenTypes.NEW_PASSWORD_ACTIVATION ||
          token.type === TokenTypes.RESET_PASSWORD);
      if (profileActiveMessage !== null) {
        token.type = TokenTypes.RESET_PASSWORD;
        requiresInput = false;
      }
      return {
        ...state,
        hasName: token.nameExist,
        autoGoto: token.autoGoto,
        errorMessage: expiredMessage,
        tokenUsedMessage,
        tokenType: token.type,
        profileActiveMessage,
        requiresInput,
        isFetching: false,
        requiresResendForm:
          expiredMessage !== null && token.type !== TokenTypes.CHANGE_EMAIL,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
        activationTitle: token.activationTitle,
        activationDetails: token.activationDetails
      };
    }
    case `${TokenActionTypes.GET_TOKEN}_${REJECTED}`: {
      const errorMessage = processGetTokenReject(action.payload);
      return {
        ...state,
        errorMessage,
        isFetching: false,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId)
      };
    }

    case `${TokenActionTypes.PATCH_TOKEN}_${PENDING}`: {
      return {
        ...state,
        isFetching: true,
        requestId: ""
      };
    }
    case `${TokenActionTypes.PATCH_TOKEN}_${FULFILLED}`: {
      const token = action.payload.data;
      return {
        ...state,
        autoGoto: token.autoGoto,
        gotoUrl: token.gotoUrl,
        loginUrl: token.loginUrl,
        isFetching: false,
        patchSuccessMessage: getPatchSuccessMessage(token),
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
        requiresInput: false,
        tokenType: token.type
      };
    }
    case `${TokenActionTypes.PATCH_TOKEN}_${REJECTED}`: {
      const error = processPatchTokenReject(
        action.payload,
        action.meta.tokenType
      );
      return {
        ...state,
        errorMessage: error.errorMessage,
        isFetching: false,
        requiresInput: false,
        requiresResendForm: error.requiresResendForm,
        tokenType: action.meta.tokenType,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId)
      };
    }
    case `${TokenActionTypes.POST_TOKEN}_${FULFILLED}`: {
      return {
        ...state,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
        errorMessage: null
      };
    }
    // We don't care about the error messages in here, the resendReducer handles that.
    case `${TokenActionTypes.POST_TOKEN}_${REJECTED}`: {
      return {
        ...state,
        tokenType: action.meta.tokenType,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
        errorMessage: null
      };
    }

    default:
      return state;
  }
}
