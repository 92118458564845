/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:23:26
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-27 15:11:58
 */
export const TokenActionTypes = {
  GET_TOKEN: 'GET_TOKEN',
  PATCH_TOKEN: 'PATCH_TOKEN',
  POST_TOKEN: 'POST_TOKEN',
  POST_EMAIL_TOKEN: 'POST_EMAIL_TOKEN',
  TOKEN_VERIFIED: 'TOKEN_VERIFIED',
};

export const UserActionTypes = {
  GET_USER: 'GET_USER',
  SEARCH_USERS: 'SEARCH_USERS',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  UNBLOCK_USER: 'UNBLOCK_USER',
};

export const HelpActionTypes = {
  SUBMIT: 'SUBMIT',
};

export const TokenTypes = {
  STANDARD_ACTIVATION: 'standardActivation',
  RESEND_ACTIVATION: 'resendActivation',
  NEW_PASSWORD_ACTIVATION: 'newPasswordActivation',
  EMAIL_ONLY_ACTIVATION: 'emailOnlyActivation',
  RESET_PASSWORD: 'resetPassword',
  CHANGE_EMAIL: 'changeEmail',
  SALESFORCE_EMAIL_ACTIVATION: 'salesforceEmailActivation',
};

export const EndPoints = {
  Tokens: '/tokens',
  Users: '/users',
  Help: '/help',
  Logs: '/logs',
  LogsFallback: '/logs/fallback',
  SendAdminInstructions: '/util/sendAdminInstructions',
};

export const LocalStorageKeys = {
  expires: 'expires_at',
  profile: 'user_profile',
  sessionId: 'session_id',
  token: 'access_token',
  language: 'profile_language',
  passwordChangeStatus: 'passwordChangeStatus',
  publicSession: 'public_session',
  hashedEmail: 'hashed_email'
};

export const MessageType = {
  error: 'error',
  notice: 'notice',
  warning: 'warning',
};

export const UserStatusTypes = {
  active: 'active',
  deactivated: 'deactivated',
  pending: 'pending',
};

export const UserGroups = {
  customers: 'customers',
  partners: 'partners',
  partnerRessources: 'partner_resources',
  profileReaders: 'profile_readers',
  profileAdmins: 'profile_admins',
  emailViewers: 'email_viewers',
  permissionGranters: 'permission_granters',
  tableauOnlineServerAdmins: 'tableau_online_server_admins',
  None: '',
};

export const Scopes = {
  readMyProfile: 'read:my_profile',
  updateMyProfile: 'update:my_profile',
  updateMyPassword: 'update:my_password',
  readProfiles: 'read:profiles',
  readProfileGroups: 'read:profiles_groups',
  updateProfiles: 'update:profiles',
  updateProfilesEmail: 'update:profiles_email',
  emailViewers: 'view:password_reset_email',
  permissionGranters: 'update:profiles_groups_admin',
  updateTableauOnlineServerAdmins: 'update:tableau_online_server_admins',
};

export const Headers = {
  requestId: 'x-tableau-request-id',
  validGoto: 'x-tableau-valid-goto',
  autoGoto: 'x-tableau-auto-goto',
};

export const Language = {
  english: {
    code: 'en-US',
    label: 'English',
  },
  french: {
    code: 'fr-FR',
    label: 'Français',
  },
  deutch: {
    code: 'de-DE',
    label: 'Deutsch',
  },
  japanese: {
    code: 'ja-JP',
    label: '日本語',
  },
  chinese: {
    code: 'zh-CN',
    label: '简体中文',
  },
  korean: {
    code: 'ko-KR',
    label: '한국어',
  },
  portuguese: {
    code: 'pt-BR',
    label: 'Português',
  },
  spanish: {
    code: 'es-ES',
    label: 'Español',
  },
  italian: {
    code: 'it-IT',
    label: 'Italiano',
  },
  dutch: {
    code: 'nl-NL',
    label: 'Nederlands',
  },
};

export const ErrorMessages = {
  userAlias: 'Username_must_be_between_3_and_100_characters.',
  firstName: 'This_field_is_required.',
  lastName: 'This_field_is_required.',
  phoneNumber:
    'Phone_number_must_be_between_1_and_40_characters.',
  invalidWildCardSearch: 'Wildcard search is limited to *.',
  invalidNameSearch: 'Letters, periods, apostrophes, hyphens are allowed.  Wildcard search is limited to *.',
  invalidNumberSearch: 'Numbers and hyphens are allowed. Wildcard search is limited to *.',
  invalidField: 'Input_contains_invalid_characters.'
};

export const ErrorCode = {
  ADMIN_GROUPS_INVALID: 'ADMIN_GROUPS_INVALID',
  ADMIN_GROUPS_TABLEAU_ONLY: 'ADMIN_GROUPS_TABLEAU_ONLY',
  BAD_PASSWORD: 'BAD_PASSWORD',
  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  FORBIDDEN_UPDATE_GROUPS: 'FORBIDDEN_UPDATE_GROUPS',
  FORBIDDEN_VIEW_EMAIL: 'FORBIDDEN_VIEW_EMAIL',
  FORBIDDEN_EMAIL_EMBARGOED_COUNTRY: 'FORBIDDEN_EMAIL_EMBARGOED_COUNTRY',
  FORBIDDEN: 'FORBIDDEN',
  INVALID_ADMINISTRATIVE_AREA: 'INVALID_ADMINISTRATIVE_AREA',
  INVALID_COMPANY: 'INVALID_COMPANY',
  INVALID_COUNTRY: 'INVALID_COUNTRY',
  INVALID_DEPARTMENT: 'INVALID_DEPARTMENT',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_FIRST_NAME: 'INVALID_FIRST_NAME',
  INVALID_INDUSTRY: 'INVALID_INDUSTRY',
  INVALID_JOB_ROLE: 'INVALID_JOB_ROLE',
  INVALID_LANGUAGE: 'INVALID_LANGUAGE',
  INVALID_LAST_NAME: 'INVALID_LAST_NAME',
  INVALID_LOCALITY_NAME: 'INVALID_LOCALITY_NAME',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  INVALID_POSTAL_CODE: 'INVALID_POSTAL_CODE',
  INVALID_PREMISE: 'INVALID_PREMISE',
  INVALID_THOROUGHFARE: 'INVALID_THOROUGHFARE',
  INVALID_TITLE: 'INVALID_TITLE',
  INVALID_USER_ALIAS: 'INVALID_USER_ALIAS',
  INVALID_WILDCARD: 'INVALID_WILDCARD',
  INVALID_WILDCARD_EMAIL: 'INVALID_WILDCARD_EMAIL',
  AUTH0_TIMEOUT_ERROR: 'AUTH0_TIMEOUT_ERROR',
  AUTH0_CANNOT_PARSE_ERROR: 'AUTH0_CANNOT_PARSE_ERROR',
  AUTH0_BAD_REQUEST_ERROR: 'AUTH0_BAD_REQUEST_ERROR',
  CLIENT_NOT_FOUND_ERROR: 'CLIENT_NOT_FOUND_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  USER_ALIAS_ALREADY_EXISTS: 'USER_ALIAS_ALREADY_EXISTS',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
  USER_NOT_PENDING: 'USER_NOT_PENDING',
  CHANGE_EMAIL_ADMIN_NOT_ALLOWED: 'CHANGE_EMAIL_ADMIN_NOT_ALLOWED',
  CHANGE_EMAIL_TABLEAU_ONLY: 'CHANGE_EMAIL_TABLEAU_ONLY',
  CHANGE_EMAIL_TABLEAU: 'CHANGE_EMAIL_TABLEAU',
  SERVICE_ERROR: 'SERVICE_ERROR',
  PASSWORD_HISTORY_ERROR: 'PASSWORD_HISTORY_ERROR',
  INVALID_TABLEAU_ONLINE_SERVER_ADMIN_PASSWORD: 'INVALID_TABLEAU_ONLINE_SERVER_ADMIN_PASSWORD',
  USER_ALREADY_UPDATED: 'VALIDATION_ERROR'
};
