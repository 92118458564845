/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-11-16 21:26:33
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-01-19 09:37:44
 */
import { HELP_ROUTE } from '../routes';
import { Headers, ErrorCode } from './Constants';
import i18n from './i18n';

const t = i18n.t.bind(i18n);

export const getChangePasswordErrorMessage = (error) => {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.USER_DEACTIVATED:
        errorMessage = [
          () => t(
            'Your_account_has_been_deactivated._Please_contact_our_a_href_link_Customer_Service_team_a_for_help.',
            { link: HELP_ROUTE },
          ),
        ];
        break;
      case ErrorCode.BAD_PASSWORD:
        errorMessage = ['The_password_you_entered_does_not_match_our_records.', 'Please_try_again.'];
        break;
      case ErrorCode.UNAUTHORIZED:
      case ErrorCode.FORBIDDEN_VIEW_EMAIL:
        errorMessage = ['Were_sorry._You_are_not_authorized_to_access_the_requested_page_or_URL.'];
        break;
      case ErrorCode.NOT_FOUND:
        errorMessage = ['The_Tableau_account_could_not_be_updated_the_request_was_not_found.'];
        break;
      case ErrorCode.PASSWORD_HISTORY_ERROR:
        errorMessage = ['This_password_has_previously_been_used._Try_another.'];
        break;
      case ErrorCode.INVALID_TABLEAU_ONLINE_SERVER_ADMIN_PASSWORD:
        errorMessage = ['New password should be at least 16 characters long.'];
        break;
      default:
        errorMessage = ['Were_sorry_An_unknown_error_has_occurred.', '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ['Were_sorry_An_unknown_error_has_occurred.'];
  }
  return errorMessage;
};

export const getChangeEmailErrorMessage = (error) => {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.USER_DEACTIVATED:
        errorMessage = ['The_account_associated_with_the_current_email_has_been_deactivated.'];
        break;
      case ErrorCode.CHANGE_EMAIL_ADMIN_NOT_ALLOWED:
        errorMessage = [
          'This account belongs to an admin group and for security reasons '
            + 'cannot change to a new email address. Please remove this person from admin groups '
            + 'before changing to the new address.',
        ];
        break;
      case ErrorCode.CHANGE_EMAIL_TABLEAU_ONLY:
      case ErrorCode.CHANGE_EMAIL_TABLEAU:
        errorMessage = [
          'For security reasons, email addresses that currently belong to the '
            + 'tableausoftware.com or tableau.com domains may only be changed to another '
            + 'tableausoftware.com or tableau.com email address.',
        ];
        break;
      case ErrorCode.EMAIL_ALREADY_EXISTS:
        errorMessage = ['That_email_address_is_already_assigned_to_an_existing_Tableau_account.'];
        break;
      case ErrorCode.FORBIDDEN_VIEW_EMAIL:
        errorMessage = ['Were_sorry._You_are_not_authorized_to_access_the_requested_page_or_URL.'];
        break;
      case ErrorCode.FORBIDDEN_EMAIL_EMBARGOED_COUNTRY:
        errorMessage = ['Accounts_with_email_addresses_from_restricted_regions_are_not_allowed._For_more_information,_see_Salesforce'];
        break;
      default:
        errorMessage = ['Were_sorry_An_unknown_error_has_occurred.', '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ['Were_sorry_An_unknown_error_has_occurred.'];
  }
  return errorMessage;
};

export const getHelpErrorMessage = (error) => {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      default:
        errorMessage = ['Were_sorry_An_unknown_error_has_occurred.', '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ['Were_sorry_An_unknown_error_has_occurred.'];
  }

  return errorMessage;
};
