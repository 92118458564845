/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 19:18:38
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-20 15:20:12
 */

import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

import { UserActionTypes, Headers, ErrorCode } from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';
import i18n from '../../common/i18n';

const t = i18n.t.bind(i18n);
function getErrorMessage(error) {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.EMAIL_ALREADY_EXISTS:
        errorMessage = ['This_email_address_is_already_in_use. ', 'Please_try_again.'];
        break;
      case ErrorCode.INVALID_EMAIL:
        errorMessage = ['The_Email_you_supplied_was_invalid.'];
        break;
      case ErrorCode.INVALID_LAST_NAME:
        errorMessage = ['The_Last_Name_you_supplied_was_invalid.'];
        break;
      case ErrorCode.INVALID_FIRST_NAME:
        errorMessage = ['The_First_Name_you_supplied_was_invalid.'];
        break;
      case ErrorCode.FORBIDDEN_EMAIL_EMBARGOED_COUNTRY:
        errorMessage = ['Accounts_with_email_addresses_from_restricted_regions_are_not_allowed._For_more_information,_see_Salesforce'];
        break;
      default:
        errorMessage = ['Were_sorry_An_unknown_error_has_occurred.', '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ['Were_sorry_An_unknown_error_has_occurred.'];
  }

  return errorMessage;
}

export default function reducer(
  state = {
    errorMessage: null,
    successMessage: null,
    isFetching: false,
    clientId: null,
    requestId: '',
  },
  action,
) {
  switch (action.type) {
    case `${UserActionTypes.CREATE_USER}_${PENDING}`: {
      return { ...state, isFetching: true, requestId: '' };
    }
    case `${UserActionTypes.CREATE_USER}_${FULFILLED}`: {
      const validGoto = getHeaderFromPayLoad(action.payload, Headers.validGoto).trim();
      const autoGoto = getHeaderFromPayLoad(action.payload, Headers.autoGoto);

      let successMessage = null;
      if (validGoto && autoGoto) {
        successMessage = [
          'Thank_you_for_creating_an_account.',
          () => t('You_may_now_a_href_link_login_a_.', {
            link: validGoto,
          }),
        ];
      } else {
        successMessage = [
          'Thank_you_for_registering.',
          'You_will_receive_an_email_containing_a_link_to_confirm_this_registration.',
          'You_must_follow_the_instructions_in_the_email_in_order_to_activate_your_Tableau_account.',
        ];
      }

      return {
        ...state,
        isFetching: false,
        successMessage,
        errorMessage: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${UserActionTypes.CREATE_USER}_${REJECTED}`: {
      const errorMessage = getErrorMessage(action.payload);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        successMessage: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }

    default:
      return state;
  }
}
