/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 08:19:58
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-11-21 13:13:12
 */
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { LOCATION_CHANGE } from 'react-router-redux';

import { HELP_ROUTE } from '../../routes';
import {
  TokenActionTypes, TokenTypes, Headers, ErrorCode,
} from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';
import i18n from '../../common/i18n';

const t = i18n.t.bind(i18n);

function getErrorMessage(error, tokenType) {
  let errorMessage = null;
  if (error.response) {
    if (error.response.data.message && error.response.data.message.includes('Tableau Employee')) {
      errorMessage = ['This is not allowed for users with @tableau.com email addresses.'];
    } else {
      const requestId = getHeaderFromPayLoad(error, Headers.requestId);
      switch (error.response.data.code) {
        case ErrorCode.USER_DEACTIVATED:
          errorMessage = [
            () => t(
              'Your_account_has_been_deactivated._Please_contact_our_a_href_link_Customer_Service_team_a_for_help.',
              { link: HELP_ROUTE },
            ),
          ];
          break;
        case ErrorCode.FORBIDDEN_VIEW_EMAIL:
          errorMessage = ['You_cannot_generate_a_local_email_for_an_tableausoftware.com_or_an_tableau.com_account.'];
          break;
        case ErrorCode.USER_NOT_PENDING:
        case ErrorCode.NOT_FOUND:
          errorMessage = [
            'We were unable to access an un-activated account with the email you provided.',
            'Please try again.',
          ];
          break;
        case ErrorCode.CLIENT_NOT_FOUND_ERROR:
        case ErrorCode.AUTH0_BAD_REQUEST_ERROR:
          if (tokenType === 'resendActivation') {
            errorMessage = [
              'Invalid_request._Please_try_again_here',
            ];
          } else if (tokenType === 'resetPassword') {
            errorMessage = [
              'Invalid_request._Please_try_resetting_your_password_here:',
            ];
          } else {
            errorMessage = ['Invalid_request._Please_try_again'];
          }
          break;
        default:
          errorMessage = ["Were_sorry_An_unknown_error_has_occurred.", '<br />', `RequestId: ${requestId}`];
          break;
      }
    }
  } else {
    errorMessage = ["Were_sorry_An_unknown_error_has_occurred."];
  }

  return errorMessage;
}

function getSuccessMessage(type) {
  let result = null;
  if (type === TokenTypes.RESEND_ACTIVATION) {
    result = [
      'We_have_processed_your_request.',
      'You_will_receive_a_new_email_containing_a_link_to_confirm_your_Tableau_account_registration.',
      'You_must_follow_the_instructions_in_the_email_in_order_to_activate_your_account._An_old_activation_email_will_no_longer_work.',
    ];
  } else if (type === TokenTypes.RESET_PASSWORD) {
    result = [
      'Weve_sent_you_an_email_with_a_link_to_finish_resetting_your_password.',
      '<br />',
      'Cant_find_the_email_Try_checking_your_spam_folder.',
      '<br />',
      'If_you_still_cant_log_in_have_us_resend_the_email_or_contact_help.',
    ];
  }

  return result;
}

export default function reducer(
  state = {
    clientId: null,
    emailContent: null,
    emailSubject: null,
    emailTo: null,
    errorMessage: null,
    isFetching: false,
    sent: false,
    successMessage: null,
    requestid: '',
    tokenActionUrl: null
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {};
    }
    case `${TokenActionTypes.POST_TOKEN}_${PENDING}`: {
      return { ...state, isFetching: true, requestId: '' };
    }
    case `${TokenActionTypes.POST_TOKEN}_${FULFILLED}`: {
      const request = JSON.parse(action.payload.config.data);
      const token = action.payload.data;
      const originalTokenType = action.meta.tokenType;
      const successMessage = getSuccessMessage(originalTokenType);
      return {
        ...state,
        emailTo: request.email,
        generateLocally: request.viewEmail ? true : false,
        errorMessage: null,
        isFetching: false,
        sent: true,
        successMessage,
        tokenActionUrl: request.viewEmail ? token.actionUrl : '',
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${TokenActionTypes.POST_TOKEN}_${REJECTED}`: {
      const tokenType = action.meta && action.meta.tokenType ? action.meta.tokenType : '';
      const errorMessage = getErrorMessage(action.payload, tokenType);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        emailContent: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    default:
      return state;
  }
}
