import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import getCountries from '../../common/getCountries';
import * as UserModel from '../../models/UserModel';
import getAddressConfig from './Data/AddressConfig';
import marketingRegion1Countries from './Data/marketing-region-1-countries.json';
import Checkbox from './elements/Checkbox';
import ValidationCheckbox from './elements/ValidationCheckbox';
import ValidationSelect from './elements/ValidationSelect';

export const formTestId = 'AdditionalInfoForm-form';
export const headingTestId = 'AdditionalInfoForm-heading';
export const submitButtonTestId = 'AdditionalInfoForm-submitButton';

export class AdditionalInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: getAddressConfig(this.props.i18n.language),
      region1CountrySelected: false,
      submitDisabled: false,
    };
    this.setStateFromLanguage = this.setStateFromLanguage.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.setStateFromLanguage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.setStateFromLanguage);
  }

  setStateFromLanguage(language) {
    this.setState({ config: getAddressConfig(language) });
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  // ValidationSelect component returns `event.value` from onChange, rather than `event`.
  onCountryChange = (eventValue) => {
    const selectedCountryIso = eventValue;
    // If a country is Region 1, users are opted out of marketing communications by default.
    // If a country is not Region 1, users are opted in to marketing communications by default.
    const countryIsRegion1 = selectedCountryIso !== '' && marketingRegion1Countries[selectedCountryIso] === true;
    this.setState({ region1CountrySelected: countryIsRegion1 });
  };

  mapInputs = inputs => UserModel.mapInputsFromAdditionalInfoEdit(this.props.userModel, inputs);

  render() {
    const { t, userModel } = this.props;
    const { config, region1CountrySelected, submitDisabled } = this.state;

    return (
      <div id="form" className="AdditionalInfoForm clearfix">
        <h1 className="title heading--h2" data-test-id={headingTestId}>
          {t('Tableau_Public_needs_more_information')}
        </h1>
        <p className="country-description">{t('Select_your_country_or_region.')}</p>
        <Formsy
          className="form--full-width"
          data-test-id={formTestId}
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <div className="clearfix">
            <div className="grid--4">
              <ValidationSelect
                autoFocus
                id="country"
                name="country"
                options={getCountries(config)}
                onChange={this.onCountryChange}
                required="isEmptyString"
                title={config.label}
                translateContents={false}
                validationErrors={{
                  isEmptyString: t('You_must_select_a_country_or_region.'),
                }}
                value={userModel.country || ''}
              />
            </div>
          </div>
          <div className="clearfix">
            <div className="grid--6">
              <ValidationCheckbox
                id="codeOfConductAcceptance"
                name="codeOfConductAcceptance"
                required
                title={(
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'I_agree_to_the_Data_Policy_and_Community_Code_By_registering_you_confirm_that_you_agree_to_the_processing_of_your_personal_data_as_described_in_the_Salesforce_Privacy_Statement.',
                      ),
                    }}
                  />
                )}
                validations="isTrue"
                validationError={t('You_must_agree_before_you_can_continue.')}
              />
            </div>
          </div>
          <div className={`clearfix ${!region1CountrySelected && 'hide'}`}>
            <div className="grid--6">
              <Checkbox
                checked={!region1CountrySelected}
                id="marketingEmailOptIn"
                label={t(
                  'Yes_I_would_like_to_receive_marketing_communications_regarding_Salesforce_products_services_and_events._I_can_unsubscribe_at_any_time.',
                )}
                name="marketingEmailOptIn"
                value="true"
              />
            </div>
          </div>
          <div className="form-buttons">
            <button
              className="cta cta--secondary"
              data-test-id={submitButtonTestId}
              disabled={submitDisabled}
              id="submitAdditionalInfoButton"
              type="submit"
            >
              {t('Complete_Sign-In')}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

AdditionalInfoForm.propTypes = {
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userModel: PropTypes.object.isRequired,
};

export default translate()(AdditionalInfoForm);
